import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ContactDto, ContactEntity, ContactResource, ContactType, GetContactsParams, Page } from '@portal/shared/utils';

@Injectable()
export class ContactResourceController {
    readonly #resource: ContactResource = inject(ContactResource);

    public getContacts(params?: GetContactsParams): Observable<Page<ContactEntity>> {
        return this.#resource.getContacts(params);
    }

    public getContact(id: number): Observable<ContactEntity> {
        return this.#resource.getContact(id);
    }

    public getContactsByType(params?: {
        searchText?: string;
        contactType: ContactType;
        page: number;
        size: number;
    }): Observable<Page<ContactEntity>> {
        return this.#resource.getContactsByType(params);
    }

    public getContactsByGroup(groupId: number, params?: GetContactsParams): Observable<Page<ContactEntity>> {
        return this.#resource.getContactsByGroup(groupId, params);
    }

    public createContact(contactDto: ContactDto): Observable<ContactEntity> {
        return this.#resource.createContact(contactDto);
    }

    public deleteContact(contactId: number): Observable<void> {
        return this.#resource.deleteContact(contactId);
    }

    public updateContact(contact: ContactDto): Observable<ContactEntity> {
        return this.#resource.updateContact(contact.contactId!, contact);
    }

    public addContactGroup(contactId: number, groupId: number): Observable<ContactEntity> {
        return this.#resource.addContactGroup(contactId, groupId);
    }

    public deleteContactGroup(contactId: number, groupId: number): Observable<ContactEntity> {
        return this.#resource.deleteContactGroup(contactId, groupId);
    }
}
