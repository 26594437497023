import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { exhaustMap, Observable, pipe, Subject, takeUntil, tap } from 'rxjs';

import { AUTH_MANAGER, AUTH_NAVIGATOR, LoginDto, UserDto } from '@portal/shared/utils';

import { AuthenticationResourceController, UsersResourceController } from '../../../+api';

interface State {
    userLoadProgress: boolean;
    user: UserDto | null;

    logOutProgress: boolean;

    updatePasswordProgress: boolean;
}

const initialState: State = {
    userLoadProgress: false,
    user: null,

    logOutProgress: false,

    updatePasswordProgress: false,
};

export const AccountStore = signalStore(
    {},
    withState<State>(initialState),
    withMethods(
        (
            store,

            logoutSuccessSource = new Subject<void>(),
            logoutFailedSource = new Subject<void>(),

            loadUserSuccessSource = new Subject<UserDto>(),
            loadUserFailedSource = new Subject<void>(),

            updateUserSuccessSource = new Subject<UserDto>(),
            updateUserFailedSource = new Subject<void>()
        ) => ({
            _logoutSuccess: (): void => logoutSuccessSource.next(),
            onLogoutSuccess: (): Observable<void> => logoutSuccessSource.asObservable(),

            _logoutFailed: (): void => logoutFailedSource.next(),
            onLogoutFailed: (): Observable<void> => logoutFailedSource.asObservable(),

            _loadUserSuccess: (data: UserDto): void => loadUserSuccessSource.next(data),
            onLoadUserSuccess: (): Observable<UserDto> => loadUserSuccessSource.asObservable(),

            _loadUserFailed: (): void => loadUserFailedSource.next(),
            onLoadUserFailed: (): Observable<void> => loadUserFailedSource.asObservable(),

            _updateUserSuccess: (data: UserDto): void => updateUserSuccessSource.next(data),
            onUpdateUserSuccess: (): Observable<UserDto> => updateUserSuccessSource.asObservable(),

            _updateUserFailed: (): void => updateUserFailedSource.next(),
            onUpdateUserFailed: (): Observable<void> => updateUserFailedSource.asObservable(),
        })
    ),
    withMethods((store, accountingController = inject(AuthenticationResourceController)) => ({
        loadUserInfo: rxMethod<void>(
            pipe(
                tap(() => patchState(store, { userLoadProgress: true })),
                exhaustMap(() =>
                    accountingController.getUserInfo().pipe(
                        tapResponse({
                            next: (user: UserDto) => {
                                store._loadUserSuccess(user);
                                patchState(store, { user });
                            },
                            error: (e: HttpErrorResponse) => {
                                store._loadUserFailed();
                            },
                            finalize: () => patchState(store, { userLoadProgress: false }),
                        })
                    )
                )
            )
        ),
        logout: rxMethod<void>(
            pipe(
                tap(() => patchState(store, { logOutProgress: true })),
                exhaustMap(() =>
                    accountingController.logout().pipe(
                        tapResponse({
                            next: () => {
                                store._logoutSuccess();
                                // reset account
                            },
                            error: (e: HttpErrorResponse) => {
                                store._logoutFailed();
                            },
                            finalize: () => patchState(store, { logOutProgress: false }),
                        })
                    )
                )
            )
        ),
    })),
    withMethods((store, userController = inject(UsersResourceController)) => ({
        updatePassword: rxMethod<{ password: string; newPassword: string }>(
            pipe(
                tap(() => patchState(store, { updatePasswordProgress: true })),
                exhaustMap((data) =>
                    userController
                        .changePassword(
                            LoginDto.toChangePassword({
                                password: data.password,
                                newPassword: data.newPassword,
                                email: store.user()!.email!,
                            })
                        )
                        .pipe(
                            tapResponse({
                                next: (data: UserDto) => {
                                    store._updateUserSuccess(data);
                                },
                                error: (e: HttpErrorResponse) => {
                                    store._updateUserFailed();
                                },
                                finalize: () => patchState(store, { updatePasswordProgress: false }),
                            })
                        )
                )
            )
        ),
    })),
    withHooks(
        (
            state,
            authManager = inject(AUTH_MANAGER),
            authNavigator = inject(AUTH_NAVIGATOR),
            destroy: Subject<void> = new Subject<void>()
        ) => ({
            onInit: () => {
                state
                    .onLogoutSuccess()
                    .pipe(takeUntil(destroy))
                    .subscribe(() => {
                        void authManager.resetJwt();
                        void authNavigator.logoutNavigate();
                    });
            },
            onDestroy: () => {
                destroy.next();
                destroy.complete();
            },
        })
    )
);
