import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Direction, EmployeeDto, EmployeeEntity, EmployeeResource, Page } from '@portal/shared/utils';

@Injectable()
export class EmployeesResourceController {
    private readonly _resource: EmployeeResource = inject(EmployeeResource);

    public getEmployee(employeeId: number): Observable<EmployeeEntity> {
        return this._resource.getEmployee(employeeId);
    }

    public createEmployee(employeeDto: EmployeeDto): Observable<EmployeeEntity> {
        return this._resource.createEmployee(employeeDto);
    }

    public getEmployees(params?: {
        listAll?: boolean;
        page?: number;
        size?: number;
        sort?: string;
        direction?: Direction;
        searchText?: string;
    }): Observable<Page<EmployeeEntity>> {
        return this._resource.getEmployees(params);
    }

    public updateEmployee(employeeId: number, employeeDto: EmployeeDto): Observable<EmployeeEntity> {
        return this._resource.updateEmployee(employeeId, employeeDto);
    }

    public toggleEmployeeStatus(employeeId: number): Observable<EmployeeEntity> {
        return this._resource.toggleEmployeeStatus(employeeId);
    }

    public deleteEmployee(employeeId: number): Observable<void> {
        return this._resource.deleteEmployee(employeeId);
    }
}
