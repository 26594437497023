import { inject } from '@angular/core';
import { Actions, createEffect, FunctionalEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';

import { LocationsStore } from './locations.store';
import { SharedFeatureActions } from '../../shared-feature.actions';

const initialize: FunctionalEffect = createEffect(
    (localization = inject(LocationsStore)) =>
        inject(Actions).pipe(
            ofType(SharedFeatureActions.initialize),
            tap(() => localization.initialize())
        ),
    { functional: true, dispatch: false }
);

export const LocationsEffects = {
    initialize,
};
