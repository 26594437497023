import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LocationDto, LocationEntity, LocationResource, Page } from '@portal/shared/utils';

@Injectable()
export class LocationsResourceController {
    readonly #resource: LocationResource = inject(LocationResource);

    public createLocation(locationDto: LocationDto): Observable<LocationEntity> {
        return this.#resource.createLocation(locationDto);
    }

    public getLocations(params?: { listAll?: boolean; page?: number; size?: number }): Observable<Page<LocationEntity>> {
        return this.#resource.getLocations(params);
    }

    public getLocation(locationId: number): Observable<LocationEntity> {
        return this.#resource.getLocation(locationId);
    }

    public updateLocation(locationId: number, locationDto: LocationDto): Observable<LocationEntity> {
        return this.#resource.updateLocation(locationId, locationDto);
    }

    public deleteLocation(locationId: number): Observable<void> {
        return this.#resource.deleteLocation(locationId);
    }

    public setHeadOffice(locationId: number): Observable<LocationEntity> {
        return this.#resource.setHeadOffice(locationId);
    }

    public toggleActiveStatus(locationId: number): Observable<LocationDto> {
        return this.#resource.toggleActiveStatus(locationId);
    }
}
