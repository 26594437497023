import { Provider } from '@angular/core';

import { SharedDataAccessApiProviders } from './+api';
import { SharedDataAccessStateProviders } from './+state';

export const SharedDataAccessProviders: Provider = [
    //
    SharedDataAccessApiProviders,
    SharedDataAccessStateProviders,
];
