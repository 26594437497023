import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LoginDto, Page, UserDto, UserEntity, UsersResource } from '@portal/shared/utils';

@Injectable()
export class UsersResourceController {
    private readonly _resource: UsersResource = inject(UsersResource);

    public getAllUsers(page: number, size: number): Observable<Page<UserEntity>> {
        return this._resource.getAllUsers(page, size);
    }

    public createUser(userDto: UserDto): Observable<UserEntity> {
        return this._resource.createUser(userDto);
    }

    public getUser(userId: number, params?: { infoFull?: boolean }): Observable<UserEntity> {
        return this._resource.getUser(userId, params);
    }

    public updateUser(userId: number, userDto: UserDto): Observable<UserEntity> {
        return this._resource.updateUser(userId, userDto);
    }

    public deleteUser(userId: number): Observable<void> {
        return this._resource.deleteUser(userId);
    }

    public toggleUserActive(userId: number): Observable<UserEntity> {
        return this._resource.toggleUserActive(userId);
    }

    public toggleLocation(userId: number, locationId: number): Observable<void> {
        return this._resource.toggleLocation(userId, locationId);
    }

    public changeRole(userId: number, userDto: UserDto): Observable<UserEntity> {
        return this._resource.changeRole(userId, userDto);
    }

    public resetPassword(userId: number, userDto: UserDto): Observable<UserEntity> {
        return this._resource.resetPassword(userId, userDto);
    }

    public recordAuditLinkedTeamMember(userId: number): Observable<void> {
        return this._resource.recordAuditLinkedTeamMember(userId);
    }

    public changePassword(loginDto: LoginDto): Observable<UserEntity> {
        return this._resource.changePassword(loginDto);
    }
}
