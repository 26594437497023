import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, type, withMethods, withState } from '@ngrx/signals';
import { entityConfig, removeAllEntities, setAllEntities, withEntities } from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { Observable, pipe, Subject, switchMap, tap } from 'rxjs';

import { LocationEntity, Page, withCrudEvents } from '@portal/shared/utils';

import { LocationsResourceController } from '../../../+api';

interface State {
    loadingEntities: boolean;
}

const initialState: State = {
    loadingEntities: false,
};

const config = entityConfig({
    entity: type<LocationEntity>(),
    collection: 'locations',
    selectId: (model: LocationEntity) => model.locationId,
});

export const LocationsStore = signalStore(
    {},
    withCrudEvents(),
    withState<State>(initialState),
    withMethods((store, loadEntitiesSuccessSource = new Subject<LocationEntity[]>(), loadEntitiesFailedSource = new Subject<void>()) => ({
        _loadEntitiesSuccess: (data: LocationEntity[]): void => loadEntitiesSuccessSource.next(data),
        onLoadEntitiesSuccess: (): Observable<LocationEntity[]> => loadEntitiesSuccessSource.asObservable(),
        _loadEntitiesFailed: (): void => loadEntitiesFailedSource.next(),
        onLoadEntitiesFailed: (): Observable<void> => loadEntitiesFailedSource.asObservable(),
    })),
    withEntities(config),
    withMethods((store, locationController = inject(LocationsResourceController)) => ({
        loadAll: rxMethod<void>(
            pipe(
                tap(() => patchState(store, { loadingEntities: true })),
                switchMap(() =>
                    locationController.getLocations().pipe(
                        tapResponse({
                            next: (data: Page<LocationEntity>) => patchState(store, setAllEntities(data.content, config)),
                            error: () => patchState(store, removeAllEntities(config)),
                            finalize: () => patchState(store, { loadingEntities: false }),
                        })
                    )
                )
            )
        ),
    })),
    withMethods((store) => ({
        initialize: () => store.loadAll(),
    }))
);
