import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    GetTeamMembersParams,
    GrandSystemAccess,
    Page,
    TeamMemberDto,
    TeamMemberEntity,
    TeamMemberFilesDto,
    TeamMemberImageDto,
    TeamMemberLicenseDto,
    TeamMemberResource,
    TeamMemberTextDto,
    UploadFileData,
} from '@portal/shared/utils';

@Injectable()
export class TeamMembersResourceController {
    private readonly _resource: TeamMemberResource = inject(TeamMemberResource);

    public getTeamMembers(params?: GetTeamMembersParams): Observable<Page<TeamMemberEntity>> {
        return this._resource.getTeamMembers(params);
    }

    public getTeamMember(teamMemberId: number | null | undefined): Observable<TeamMemberEntity> {
        return this._resource.getTeamMember(teamMemberId);
    }

    public createTeamMember(dto: TeamMemberDto): Observable<TeamMemberEntity> {
        return this._resource.createTeamMember(dto);
    }

    public updateTeamMember(teamMemberId: number, dto: TeamMemberDto): Observable<TeamMemberEntity> {
        return this._resource.updateTeamMember(teamMemberId, dto);
    }

    public toggleTeamMemberStatus(teamMemberId: number): Observable<TeamMemberEntity> {
        return this._resource.toggleTeamMemberStatus(teamMemberId);
    }

    public deleteTeamMember(teamMemberId: number): Observable<void> {
        return this._resource.deleteTeamMember(teamMemberId);
    }

    public addProfession(teamMemberId: number, profession: TeamMemberLicenseDto): Observable<TeamMemberDto> {
        return this._resource.addProfession(teamMemberId, profession);
    }

    public deleteProfession(licenseId: number): Observable<void> {
        return this._resource.deleteProfession(licenseId);
    }

    public editProfession(license: TeamMemberLicenseDto): Observable<TeamMemberDto> {
        return this._resource.editProfession(license);
    }

    public getLicenses(teamMemberId: number): Observable<TeamMemberFilesDto[]> {
        return this._resource.getLicenses(teamMemberId);
    }

    public uploadLicense(uploadFileData: UploadFileData): Observable<TeamMemberFilesDto[]> {
        return this._resource.uploadLicense(uploadFileData);
    }

    public deleteLicense(fileId: number): Observable<TeamMemberFilesDto[]> {
        return this._resource.deleteLicense(fileId);
    }

    public downloadFile(fileId: number): Observable<Blob> {
        return this._resource.downloadFile(fileId);
    }

    public viewTextFile(fileId: number): Observable<TeamMemberTextDto> {
        return this._resource.viewTextFile(fileId);
    }

    public viewImageFile(fileId: number): Observable<TeamMemberImageDto> {
        return this._resource.viewImageFile(fileId);
    }

    public grantSystemAccess(params: GrandSystemAccess): Observable<TeamMemberDto> {
        return this._resource.grantSystemAccess(params);
    }
}
