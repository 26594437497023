import { inject, Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';

import { SharedFeatureActions } from './shared-feature.actions';

@Injectable()
export class SharedFeatureEventBus {
    private readonly actions = inject(Actions);

    public onInitialize(): Observable<void> {
        return this.actions.pipe(ofType(SharedFeatureActions.initialize));
    }
}
